<template>
  <div class="comfirmPage">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <i class="el-icon-arrow-left" @click="backPageJump"></i>
          <div class="header_title">
            <span>订单信息-佛山灏海</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="main">
          <!-- 用户信息 -->
          <div class="userList">
            <div class="pickTil">用户信息</div>
            <div class="addressPart">
              <div class="addressLeft">
                <div
                  class="addressList"
                  v-for="(item, index) in addressList"
                  :key="index"
                  @click="changeAddress"
                >
                  <div class="leftIcon">
                    <div class="showIcon"><i class="el-icon-s-home" /></div>
                  </div>
                  <div class="rightInside">
                    <div class="highTop">
                      <b>{{ item.name }}</b>
                      <span>{{ item.mobile }}</span>
                    </div>
                    <div class="highAddress">{{ item.address }}</div>
                  </div>
                  <div class="rightIcon">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品信息 -->
          <div class="shopGoodMain">
            <div class="goodsTil">商品信息</div>
            <div class="goodsMain">
              <div class="goodsList" v-for="(item, index) in goodsList" :key="index">
                <div class="leftImg">
                  <img v-if="item.imgUrl === null || item.imgUrl === ''" src="../assets/nodata.png" alt="">
                  <img v-else :src="'http://ssmy.xinglian.info/file/'+item.imgUrl" alt="">
                </div>
                <div class="rightValue">
                  <div class="tilName">{{ item.name }}</div>
                  <div class="priceList">
                    <div class="leftPrice">￥{{ item.price }}</div>
                    <div class="numSet">&times; {{ item.count }}</div>
                  </div>
                </div>
              </div>
              <div class="addressCount" v-if="addressId && addressId !== 0">配送费用：<span>{{ addressPrice }}</span></div>
              <div class="valueCount">合计费用：<span>{{ totalPrice }}</span></div>
            </div>
          </div>
          <!-- 备注信息填写 -->
          <div class="remarkMain">
            <div class="pickTil">备注信息</div>
            <el-input
              v-model="dataForm.remarks"
              type="textarea"
              class="remarksBlock"
              rows="4"
            />
          </div>
          <div class="clearBtnList">
            <el-button
              type="primary"
              class="sureCheck"
              :loading="isLoading"
              @click="dataFormSubmit"
            >确认支付</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
</div>
</template>
<script>
import moment from "moment";
moment.locale("zh-cn");
export default {
  data() {
    return {
      totalPrice: 0,  // 总金额
      totalNum: 0,  // 总数量
      addressPrice: 0,  // 配送费用
      isLoading: false,
      isVisible: false, // 是否显示日期选择器
      addressVisible: false,
      nameVisible: false,
      nowDate: moment(new Date()).format("YYYY-MM-DD"), // 时间选择器中，只能选择今天后的日期数据
      chooseDate: null, // 取货日期
      chooseWeek: null, // 取货星期几回显
      goodsList: [],  // 商品列表
      dataForm: {
        remarks: null
      },
      addressAllList: [{
        id: 1,
        name: "某系人",
        mobile: "13679830253",
        address: "青田区选择人",
        isNess: 1
      }],
      addressList: [  // 只保留一个地址id
        {
          id: 1,
          name: "某系人",
          mobile: "13679830253",
          address: "青田区选择人",
          isNess: 1
        }
      ],
      addressTypeName: "公司自提", //  取货方式名称
      addressType: 0, // 取货方式id
      addressName: "请选择", // 取货岗段
      addressId: 0, // 取货岗段对应的id
      menuItems: [
        {name: "公司自提", value: 0},
        // {name: "配送到指定地址", value: 1}
      ],
      nameItems: []
    }
  },
  mounted() {
    this.checkAddressId();
    // this.getLocationPrice();
    this.checkDataCode();
  },
  methods: {
    // 检查是否有存入的cookies的地址id
    checkAddressId() {
      let getAddressId = this.$cookies.get("addressId");

      console.log(getAddressId)
    },
    changeAddress() {
      this.$router.push({ name: "address", query: {type: 1} })
    },
    // 检查获取当前数据是否完整
    checkDataCode() {
      this.isVisible = false; // 默认一进来，日期栏，不做弹出处理
      this.chooseDate = moment(new Date()).format("YYYY-MM-DD");
      let goodsData = JSON.parse(sessionStorage.getItem("orderGoods"));
      if (goodsData && goodsData.length > 0) {
        this.goodsList = goodsData;
      }
      this.checkPriceAndTotalNum();
    },
    //  跳转回购物车列表
    backPageJump() {
      this.$router.replace({ name: "shopcart", params: {isBack: 1} })
    },
    // 计算总金额
    checkPriceAndTotalNum(){
      this.totalNum = 0;
      this.totalPrice = 0;
      this.goodsList.forEach(item => {
        if (item.choose) {
          this.totalNum += item.count;
          this.totalPrice += parseFloat(item.count * item.price);
        }
      })
      if (this.addressId) {
        this.totalPrice += this.addressPrice
      }
    },
    // 获取配送地区费用的接口
    getLocationPrice() {
      // 判断当前是否开启配送模式
      this.$http.httpGet(`/mobile/onlineShop/listByRetailDeliveryCost`).then(data => {
        if (data && data.code === 0) {
          this.nameItems = data.page
        } else {
          this.nameItems =[];
          this.$message.error(data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 提交支付申请 后调起支付请求
    dataFormSubmit() {
      this.isLoading = true;
      let TBusinessCost = [];
      this.goodsList.forEach(item => {
        if (item.choose){
          const good = {serviceId:item.id, count:item.count};
          TBusinessCost.push(good);
        }
      });
      // console.log(TBusinessCost)

      //生成订单
      this.$http.httpPost('mobile/onlineShop/save',{
        type: 1,
        buyId: this.buyId ? this.buyId : null,
        remarks: this.dataForm.remarks,
        receivableCost: this.totalPrice,
        totalCost: this.totalPrice,
        pickTime: this.chooseDate + " 00:00:00",
        addTBusinessCostVOS: TBusinessCost,
        // returl: process.env.VUE_APP_PAY_URL + "payStatus.html?t=" + (+new Date()),
      }).then(data => {
        if (data && data.code === 0) {
          sessionStorage.removeItem("orderGoods");
          this.isLoading = false;
          if (data.status === 0) {
            this.$message.success("支付成功")
          } else {
            this.$message.success("该订单已支付，无法进行支付")
          }
          this.$router.replace({ name: "mine" });
        } else {
          if (data.status === 100) {
            this.$message.error("用户余额不足");
          } else if (data.status === 400) {
            this.$message.error("查询无此用户");
          } else {
            this.$message.error("无权限访问");
          }
          this.isLoading = false;
        }
      }).catch(() => {
        this.$message.error(data.msg)
        this.isLoading = false;
      });
    },
  },
}
</script>
<style scoped lang="scss">
.addressPart {
  width: 100%;
  .addressList {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    .leftIcon {
      width: 40px;
      padding: 10px;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .showIcon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(rgba(41, 143, 255, 0.6), rgba(41, 143, 255, 1));
        i {
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .rightInside {
      flex: 1;
      position: relative;
      padding: 10px 10px 10px 0;
      .highTop {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 8px 0;
        b {
          font-size: 16px;
          color: #000;
          line-height: 20px;
          font-weight: 600;
        };
        span {
          font-size: 14px;
          color: #333;
          line-height: 20px;
          margin: 0 0 0 10px;
        }
        .inDefault {
          border: 1px #298FFF solid;
          font-size: 12px;
          padding: 0 4px;
        }
      }
      .highAddress {
        font-size: 13px;
        line-height: 1.4;
        word-break: break-all;
      }
    }
    .rightIcon {
      width: 40px;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 18px;
        color: #999;
      }
    }
    .announceTop {
      font-size: 16px;
      padding: 8px 10px;
      font-weight: 600;
      line-height: 1.4;
      max-height: 40px;
      letter-spacing: 1px;
      word-break: break-all;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      border-bottom: 1px #efefef solid;
    }
    .announceCon {
      font-size: 13px;
      padding: 15px 10px 0;
      text-indent: 2em;
      line-height: 1.6;
      max-height: 52px;
      word-break: break-all;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .announceBottom {
      margin: 15px 0 0;
      border-top: 1px #efefef solid;
      font-size: 13px;
      color: #000;
      line-height: 1.6;
      padding: 10px;
      text-align: right;
    }
  }
}

</style>
